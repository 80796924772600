/* eslint-disable camelcase */
import { Article, getArticles } from 'ic-js-content'
import { getCompetitionTableById } from 'ic-js-rugby'
import { BridgeImage } from '~/assets/types/util'
import { Team } from '~/assets/types/teams'

const getSanitizedTeam = (teamArticle: Article, datafeedTeam, runtimeConfig) => {
	if (!teamArticle || !datafeedTeam) return
	const teamCustomContentType = runtimeConfig?.public?.clubCustomContentType
	const id = teamArticle?.linkedIds?.find(id => id?.sourceSystem === runtimeConfig?.public?.sourceSystem.team)?.sourceSystemId
	const name = teamArticle?.heroMedia?.title || ''
	const slug = teamArticle?.slug
	const nameFirstPart = getNameFirstPart(name || '')
	const nameSecondPart = getNameSecondPart(name || '')
	const {
		backgroundDark_string,
		backgroundLight_string,
		clubBackgroundGraphic_content,
		clubHeadingGraphicDesktop_content,
		clubHeadingGraphicMobile_content,
		clubSocialNetworks_custom,
		buyTicketsLink_string,
		prtvPass_string,
		stadium_string,
		yearFounded_string,
		website_string,
		myTeamHeaderMobile_content,
		myTeamHeaderDesktop_content,
	} = teamArticle?.content?.find(item => item?.customContentType === teamCustomContentType?.config)?.customContent
	// used in match centre lineups, player profile hero, standingstable and club page
	const logoDark = {
		src: datafeedTeam.imageUrls.ON_LIGHT || datafeedTeam.imageUrls.DEFAULT,
		alt: name,
	}
	const logoLight = {
		src: datafeedTeam.imageUrls.ON_DARK || datafeedTeam.imageUrls.DEFAULT,
		alt: name,
	}
	const backgroundGraphic = setImage(clubBackgroundGraphic_content, name)
	const headerGraphicMobile = setImage(clubHeadingGraphicMobile_content, name)
	const headerGraphicDesktop = setImage(clubHeadingGraphicDesktop_content, name)
	const myTeamHeaderMobile = setImage(myTeamHeaderMobile_content, name)
	const myTeamHeaderDesktop = setImage(myTeamHeaderDesktop_content, name)
	const socialNetworks = clubSocialNetworks_custom?.[0]
	return {
		colorDark: backgroundDark_string,
		colorLight: backgroundLight_string,
		id,
		logoDark,
		logoLight,
		backgroundGraphic,
		headerGraphicMobile,
		headerGraphicDesktop,
		myTeamHeaderMobile,
		myTeamHeaderDesktop,
		name,
		nameFirstPart,
		nameSecondPart,
		socialNetworks,
		buyTicketsLink: buyTicketsLink_string,
		prtvPass: prtvPass_string,
		slug,
		stadium: stadium_string,
		yearFounded: yearFounded_string,
		website: website_string,
	} as Team
}

const getNameSeparated = (fullName: string) => {
	return fullName?.split(' ')
}

const getNameFirstPart = (fullName: string) => {
	return getNameSeparated(fullName)?.slice(0, -1).join(' ')
}

const getNameSecondPart = (fullName: string) => {
	if (fullName) return getNameSeparated(fullName)?.at(-1)
	return null
}

const setImage = (imageData: BridgeImage, alternativeAlt: string) => {
	if (!imageData?.image) return null
	return {
		src: imageData.image,
		alt: imageData.altText || alternativeAlt || '',
	}
}

const sortTeamsByName = (teams: Team[]) => {
	return teams.sort((a: Team, b: Team) => {
		return (a.name as string) > (b.name as string) ? 1 : -1
	})
}

export const useTeamsStore = defineStore('teams', () => {
	const runtimeConfig = useRuntimeConfig()
	let teams = ref<Team[]>()

	const initTeamsStore = async () => {
		if (teams.value?.length) return
		const articleParams = {
			tags: 'team',
			singlePage: true,
		}

		const teamsParams = {
			provider: runtimeConfig.public.dataProvider,
			seasonId: runtimeConfig.public.season,
			images: true,
		}

		const articlesResponse = await getArticles({ params: articleParams })
		const teamsResponse = await getCompetitionTableById(runtimeConfig.public.compId, { params: teamsParams })
		if (articlesResponse?.status !== 'success' || teamsResponse?.status !== 'success') return
		const datafeedTeams = teamsResponse?.data?.groups?.flatMap(group => group.teams)
		const teamArticles = articlesResponse?.data?.articles?.map((article: Article) => article)
		if (!teamArticles?.length) return
		const unsortedTeams = teamArticles?.map(teamArticle => {
			const matchingDatafeedTeam = datafeedTeams?.find(
				datafeedTeam => {
					const teamLinkedId = teamArticle.linkedIds.find(
						linkedId => linkedId.sourceSystem === 'RUGBYVIZ_RUGBY_UNION_TEAM',
					)
					const teamId = teamLinkedId?.sourceSystemId
					return teamId?.toString() === datafeedTeam.teamId.toString()
				},
			)
			return getSanitizedTeam(teamArticle, matchingDatafeedTeam, runtimeConfig) as Team
		})
		teams.value = sortTeamsByName(unsortedTeams)
	}

	return {
		teams,
		initTeamsStore,
	}
})
